import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Ensure the path to AuthContext is correct

function ProtectedRoute({ component: Component }) {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    // Redirect to SignIn and pass the current location in state
    return <Navigate to="/signin" state={{ from: location }} replace />;
}

  return <Component />;
}

export default ProtectedRoute;
