import React from 'react';
import ReactMarkdown from 'react-markdown';

function convertMailSummaryToMarkdown(details) {
  let markdown = "";
  const keys = ["수신자", "발신자", "발신 날짜", "본문 요약"]; // Explicit order for "메일 요약"
  keys.forEach(key => {
      if (details[key]) {
          const value = details[key];
          if (typeof value === 'object' && value !== null && (key === "수신자" || key === "발신자")) {
              const nameWithEmail = `[${value["이름"]}](mailto:${value["이메일"]}) (${value["직책"]}, ${value["회사"]})`;
              markdown += `**${key}:** ${nameWithEmail}\n\n`;
          } else {
              markdown += `**${key}:** ${value}\n\n`;
          }
      }
  });
  return markdown;
}

function convertQuoteSummaryToMarkdown(details) {
  const order = ['견적서 제목', '견적서 정보', '제품 상세', '총계', '부가가치세', '합계', '비고']; // Explicit order for "견적서 요약"
  let markdown = "";

  order.forEach(key => {
      if (details[key]) {
          const value = details[key];
          if (key === "제품 상세" && Array.isArray(value)) {
              // Special formatting for "제품 상세"
              markdown += `**${key}:**\n\n`; // Add a newline before starting product details
              value.forEach(detail => {
                  // Display each product detail as a list item
                  markdown += `* ${Object.keys(detail).map(detailKey => `**${detailKey}**: ${detail[detailKey]}`).join(', ')}\n`;
              });
              markdown += `\n`; // Ensure a newline is added after the list
          } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
              // Formatting for "견적서 정보" or any other nested object
              markdown += `**${key}:**\n`;
              Object.keys(value).forEach(subKey => {
                  markdown += `**${subKey}**: ${value[subKey]}\n`;
              });
              markdown += `\n`; // Ensure a newline is added for separation
          } else {
              // General formatting for strings and other types
              markdown += `**${key}:** ${value}\n\n`; // Ensure a newline is added for separation
          }
      }
  });

  return markdown;
}

function QuoteDetail({ quote, setSelectedQuote }) {
  const mailSummaryMarkdown = quote["메일 요약"] ? convertMailSummaryToMarkdown(quote["메일 요약"]) : '';
  const quoteSummaryMarkdown = quote["견적서 요약"] ? convertQuoteSummaryToMarkdown(quote["견적서 요약"]) : '';

  return (
      <div className="w-1/2 p-4 border-l bg-white rounded-lg shadow-lg overflow-auto">
          <button onClick={() => setSelectedQuote(null)} className="mb-4 text-gray-500 hover:text-gray-700">닫기</button>
          <div className="mb-6">
              <div className="font-bold text-xl mb-2">메일 요약</div>
              <ReactMarkdown>{mailSummaryMarkdown}</ReactMarkdown>
          </div>
          <div>
              <div className="font-bold text-xl mb-2">견적서 요약</div>
              <ReactMarkdown>{quoteSummaryMarkdown}</ReactMarkdown>
          </div>
      </div>
  );
}

export default QuoteDetail;
