import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Doctor Q
        </p>
        <Link to="/quotes" className="App-link">
          Search Quotes
        </Link>
      </header>
    </div>
  );
}

export default Home;
