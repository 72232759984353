import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBS4lthy0M3a1cgGnMw9bn7Fa97IjhAS08",
  authDomain: "doctorq.firebaseapp.com",
  projectId: "doctorq",
  storageBucket: "doctorq.appspot.com",
  messagingSenderId: "902112065778",
  appId: "1:902112065778:web:a0aad54e03c338e01fb86b",
  measurementId: "G-3JD57GRB60"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get auth and firestore instances
export const auth = getAuth(app);
export const firestore = getFirestore(app);
