import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { collection, query, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import QuoteList from './QuoteList';
import QuoteDetail from './QuoteDetail';

function Quotes() {
    const [quotes, setQuotes] = useState([]);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchQuotes = async () => {
        setLoading(true);
        const first = query(collection(firestore, "quotations"), orderBy("메일 요약.발신 날짜"), limit(20));
        const documentSnapshots = await getDocs(first);

        const quotes = documentSnapshots.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        setQuotes(quotes);
        setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
        setLoading(false);
    };

    const fetchMoreQuotes = async () => {
        if (lastVisible) {
            setLoading(true);
            const next = query(collection(firestore, "quotations"), orderBy("메일 요약.발신 날짜"), startAfter(lastVisible), limit(20));
            const documentSnapshots = await getDocs(next);

            const newQuotes = documentSnapshots.docs.map(doc => ({ id: doc.id, data: doc.data() }));
            setQuotes(prevQuotes => [...prevQuotes, ...newQuotes]);
            setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                fetchMoreQuotes();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastVisible]);

    useEffect(() => {
        fetchQuotes();
    }, []);

    return (
        <div className="flex p-5">
            <QuoteList
                quotes={quotes}
                loading={loading}
                fetchMoreQuotes={fetchMoreQuotes}
                setSelectedQuote={setSelectedQuote}
                selectedQuote={selectedQuote}
            />
            {selectedQuote && (
                <QuoteDetail
                    quote={selectedQuote}
                    setSelectedQuote={setSelectedQuote}
                />
            )}
        </div>
    );
}

export default Quotes;
