import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; 
import ProtectedRoute from './ProtectedRoute';

// Import your components
import Home from './Home';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Quotes from './quotes/Quotes'; 
import Demo from './Demo'; 

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gray-100">
          <nav className="bg-white shadow-md">
            <ul className="flex justify-center space-x-4 p-4">
              <li className="text-blue-500 hover:text-blue-700">
                <Link to="/">Home</Link>
              </li>
              <li className="text-blue-500 hover:text-blue-700">
                <Link to="/quotes">Quotes</Link>
              </li>
            </ul>
          </nav>

          {/* A <Routes> replaces the old <Switch> and is used to conditionally render components based on the path */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/quotes" element={<ProtectedRoute component={Quotes} />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
