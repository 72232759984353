import React from 'react';

function QuoteList({ quotes, loading, setSelectedQuote, selectedQuote }) {
    return (
        <div className={`overflow-auto transition-all duration-500 ${selectedQuote ? 'w-1/2 mr-4' : 'w-full'}`} style={{ height: 'calc(100vh - 20px)', marginTop: '10px' }}>
            <div className="sticky top-0 bg-gray-100 z-10">
                <h1 className="text-2xl font-bold mb-0 p-2 shadow">견적 조회 결과</h1>
            </div>
            <div className="space-y-4 p-5">
                {quotes.map(({ id, data }) => (
                    <div key={id} className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl cursor-pointer"
                         onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f3f4f6'}
                         onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
                         onClick={() => setSelectedQuote(data)}>
                        <h2 className="text-xl font-semibold">{data["메일 요약"]["수신자"]["회사"]}</h2>
                        <p className="text-gray-600"><strong>수신:</strong> {data["메일 요약"]["수신자"]["이름"]} ({data["메일 요약"]["수신자"]["직책"]}, {data["메일 요약"]["수신자"]["회사"]})</p>
                        <p className="text-gray-600"><strong>날짜:</strong> {data["메일 요약"]["발신 날짜"]}</p>
                        <p className="text-gray-600"><strong>요약:</strong> {data["메일 요약"]["본문 요약"]}</p>
                    </div>
                ))}
            </div>
            {loading && <p className="p-5">Loading more...</p>}
        </div>
    );
}

export default QuoteList;
